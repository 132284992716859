exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-innovatorsnetwork-index-js": () => import("./../../../src/pages/innovatorsnetwork/index.js" /* webpackChunkName: "component---src-pages-innovatorsnetwork-index-js" */),
  "component---src-pages-leaders-error-box-js": () => import("./../../../src/pages/leaders/ErrorBox.js" /* webpackChunkName: "component---src-pages-leaders-error-box-js" */),
  "component---src-pages-leaders-event-js": () => import("./../../../src/pages/leaders/event.js" /* webpackChunkName: "component---src-pages-leaders-event-js" */),
  "component---src-pages-leaders-index-js": () => import("./../../../src/pages/leaders/index.js" /* webpackChunkName: "component---src-pages-leaders-index-js" */),
  "component---src-pages-podcasts-js": () => import("./../../../src/pages/podcasts.js" /* webpackChunkName: "component---src-pages-podcasts-js" */),
  "component---src-pages-preview-case-study-js": () => import("./../../../src/pages/preview/case-study.js" /* webpackChunkName: "component---src-pages-preview-case-study-js" */),
  "component---src-pages-preview-index-js": () => import("./../../../src/pages/preview/index.js" /* webpackChunkName: "component---src-pages-preview-index-js" */),
  "component---src-pages-preview-people-js": () => import("./../../../src/pages/preview/people.js" /* webpackChunkName: "component---src-pages-preview-people-js" */),
  "component---src-pages-preview-perspectives-js": () => import("./../../../src/pages/preview/perspectives.js" /* webpackChunkName: "component---src-pages-preview-perspectives-js" */),
  "component---src-templates-basic-js": () => import("./../../../src/templates/basic.js" /* webpackChunkName: "component---src-templates-basic-js" */),
  "component---src-templates-basic-sections-js": () => import("./../../../src/templates/basicSections.js" /* webpackChunkName: "component---src-templates-basic-sections-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-china-home-js": () => import("./../../../src/templates/chinaHome.js" /* webpackChunkName: "component---src-templates-china-home-js" */),
  "component---src-templates-china-team-js": () => import("./../../../src/templates/chinaTeam.js" /* webpackChunkName: "component---src-templates-china-team-js" */),
  "component---src-templates-company-js": () => import("./../../../src/templates/company.js" /* webpackChunkName: "component---src-templates-company-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-list-js": () => import("./../../../src/templates/list.js" /* webpackChunkName: "component---src-templates-list-js" */),
  "component---src-templates-people-js": () => import("./../../../src/templates/people.js" /* webpackChunkName: "component---src-templates-people-js" */)
}

