module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"vuk1lzi"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kleiner Perkins","short_name":"KP","description":"Kleiner Perkins is an American venture capital firm headquartered on Sand Hill Road in Menlo Park in Silicon Valley.","lang":"en","display":"standalone","icon":"src/images/favicons/favicon@6x.png","start_url":"/","background_color":"#000","theme_color":"#fff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e4ea04c62342acbfc8d5fa293c4c3ab8"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
